@value Blackberry, White from '~goustouicomponents/dist/styles/colors.module.css';
@value PaddingFooter 1.5rem;
@value BottomSpace 1rem;
@value SpaceBetweenLinks 2rem;

.wrapper {
  background: Blackberry;
}

.container {
  margin: auto;
  padding-top: PaddingFooter;
  padding-bottom: calc(PaddingFooter - BottomSpace);
  color: White;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.logo {
  display: block;
  max-width: 7rem;
  margin-bottom: BottomSpace;
  margin-right: SpaceBetweenLinks;
}

.logo img {
  display: block;
  width: 100%;
}

.linksWrapper {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.linksWrapper li {
  margin: 0 0 BottomSpace;
  padding: 0;
}

.linksWrapper li:not(:last-child) {
  margin-right: SpaceBetweenLinks;
}

.linkTitle {
  margin: 0;
  font-weight: 400;
}

.linksWrapper a {
  color: inherit !important;
}
