@value Blackberry, Bluecheese, White from '~goustouicomponents/dist/styles/colors.module.css';
@value BreakpointFromMedium from '~goustouicomponents/dist/design-language/breakpoints.module.css';
@value PaddingSmallScreen 2rem;
@value PaddingMediumScreen 4rem;

.wrapper {
  color: Blackberry;
  background: White;
  background-size: cover;
  background-position: center;
  padding-top: PaddingSmallScreen;
  padding-bottom: PaddingSmallScreen;
}

.heading {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media BreakpointFromMedium {
  .wrapper {
    padding-top: PaddingMediumScreen;
    padding-bottom: PaddingMediumScreen;
  }
}

.seasonHeader {
  composes: fontStyleBody  from '~goustouicomponents/dist/design-language/typography.module.css';
  font-weight: 400;
  color: White;
  margin: 0 0 1.5rem;
  border-bottom: 1px solid Blackberry;
}

.seasonHeaderText {
  display: inline-block;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: Blackberry;
  padding: 0.25rem 0.5rem;
}

.sorting {
  composes: fontStyleBody  from '~goustouicomponents/dist/design-language/typography.module.css';
  appearance: none;
  padding: none;
  margin: none;
  background: transparent;
  border: none;
  color: Bluecheese;
  cursor: pointer;
}

.sorting:hover,
.sorting:active,
.sorting:focus {
  text-decoration: underline;
}

.sortingArrow {
  margin-right: 0.25rem;
  transition: transform 300ms ease;
}

.isActive .sortingArrow {
  transform: rotate(180deg);
}
