@value White from '~goustouicomponents/dist/styles/colors.module.css';
@value BreakpointToLarge, BreakpointFromLarge from '~goustouicomponents/dist/design-language/breakpoints.module.css';

.wrapper {
  color: White;
  background: #212930;
  background-image: url(../../assets/backgrounds/heroMessage.svg);
  background-position: center;
  padding: 2.5rem 0;
}

.headingWrapper {
  margin-bottom: 1.5rem;
}

.episodesWrapper {
  margin-bottom: 1.5rem;
}

.ctaWrapper {
  display: flex;
  justify-content: center;
}

@media BreakpointToLarge {
  .episodeWrapper:not(:last-child) {
    margin-bottom: 1rem;
  }
}

@media BreakpointFromLarge {
  .wrapper {
    padding: 3rem 0;
  }

  .headingWrapper {
    margin-bottom: 2rem;
  }

  .episodesWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;
  }

  .episodeWrapper {
    flex: 1 1;
  }

  .episodeWrapper:not(:last-child) {
    margin-right: 1.5rem;
  }
}
