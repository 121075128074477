@value Coconut, White from '~goustouicomponents/dist/styles/colors.module.css';
@value BreakpointFromMedium from '~goustouicomponents/dist/design-language/breakpoints.module.css';

.narrowContent {
  max-width: 46rem;
  margin: 0 auto;
  width: 100%;
}

.episodeHero {
  background: Coconut;
}

.episodeHeroContent {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  align-items: center;
  max-width: 58rem;
  margin: 0 auto;
  text-align: center;
}

.imageWrapper {
  max-width: 20rem;
  margin-bottom: 1rem;
  background-image: url(../../assets/backgrounds/thumbnail.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1rem;
}

.image {
  display: block;
  width: 100%;
}

.dateAndDuration {
  margin-bottom: 1rem;
}

.episodeDetails {
  background: White;
  padding: 2rem 0;
}

@media BreakpointFromMedium {
  .episodeHeroContent {
    padding: 2rem 0;
  }

  .imageWrapper {
    margin-bottom: 1.5rem;
    padding: 1.5rem;
  }

  .dateAndDuration {
    margin-bottom: 2rem;
  }

  .episodeDetails {
    padding: 2.5rem 0;
  }
}
