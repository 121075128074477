@import '~goustouicomponents/dist/design-language/general-setup.module.css';

@value Coconut from '~goustouicomponents/dist/styles/colors.module.css';
@value FontStyleBody from '~goustouicomponents/dist/design-language/typography.module.css';

html {
  background: Coconut;
}

/* defence CSS - to counteract existing patterns from the old brand */
body {
  font: FontStyleBody; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
}
