@value Blackberry, White from '~goustouicomponents/dist/styles/colors.module.css';
@value BreakpointToMedium, BreakpointFromMedium from '~goustouicomponents/dist/design-language/breakpoints.module.css';
@value PaddingWrapper 3rem;
@value BottomSpace 1.5rem;
@value LinkBottomGap 1rem;
@value IconSize 1.5rem;
@value HeroImage 19rem;

.heroImage {
  position: relative;
}

.goustoLogo {
  z-index: 1;
  mask-image: none;
  position: absolute;
  width: 88px;
}

.arrowDown {
  display: block;
  margin: 0 auto;
  padding-bottom: 1rem;
}

.arrowDownWrapper {
  position: relative;
  top: -10px;
}

.podcastLogo {
  position: relative;
  top: -10px;
  left: 50%;
  transform: translate(-50%);
}

@media BreakpointToMedium {
  .arrowDownWrapper {
    display: none;
  }

  .podcastLogo {
    width: 100%;
  }

  .heroImage {
    display: flex;
    flex-direction: column;
  }
}

@media BreakpointFromMedium {
  .wrapper {
    background-image: url(../../assets/backgrounds/heroMessage.svg);
    background-color: Blackberry;
    background-repeat: repeat;
  }

  .heroImage {
    width: 90%;
    margin: 0 auto;
  }

  .mainImage {
    mask-image: linear-gradient(rgba(0, 0, 0, 1), 70%, transparent);
  }

  .podcastLogo {
    top: -30px;
    max-width: 340px;
  }

  .goustoLogo {
    width: 111px;
  }
}
