@value Blackberry, Bluecheese from '~goustouicomponents/dist/styles/colors.module.css';
@value BreakpointToMedium, BreakpointFromLarge from '~goustouicomponents/dist/design-language/breakpoints.module.css';
@value IconSize 1.5rem;

.wrapper {
  color: Blackberry;
}

.textWrapper {
  text-align: center;
  padding: 2rem 1rem 1rem;
  max-width: 550px;
  margin: 0 auto;
}

.description {
  composes: fontStyleBodyL from '~goustouicomponents/dist/design-language/typography.module.css';
}

.linkContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.linkContainer > div {
  margin-bottom: 1rem;
}

.linkTitle {
  padding-bottom: 0.5rem;
  margin: 0;
}

.linkList {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.linkList li {
  padding: 0;
  margin: 0 1rem 0 0;
}

.linkList a {
  border-bottom: none !important;
  display: block;
}

.linkList button {
  width: IconSize;
}

.shareLink svg {
  color: Bluecheese;
}

.linkList button svg {
  font-size: IconSize;
}

.playerLogo {
  width: IconSize;
  height: IconSize;
}

.shareLink {
  transform: scale(1);
  transition: transform 200ms ease;
}

.shareLink:hover,
.shareLink:focus,
.shareLink:active {
  transform: scale(1.2);
}

.copylink {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}

.copylink:hover {
  cursor: pointer;
}

.infoTipWrapper {
  position: relative;
  left: -190%;
}

@media BreakpointToMedium {
  .linkContainer {
    flex-direction: column;
  }

  .linkList {
    justify-content: center;
  }
}

@media BreakpointFromLarge {
  .textWrapper {
    max-width: 800px;
    margin: 0 auto;
  }
}
