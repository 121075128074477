@value Blackberry, Bluecheese, White from '~goustouicomponents/dist/styles/colors.module.css';
@value fontStyleXS, fontStyleSubHead from '~goustouicomponents/dist/design-language/typography.module.css';
@value BreakpointToMedium, BreakpointFromMedium from '~goustouicomponents/dist/design-language/breakpoints.module.css';
@value contentPadding 1.5rem;

.wrapper {
  display: block;
  background: White;
  color: Blackberry;
  text-decoration: none;
}

.content {
  display: block;
  padding: contentPadding;
  position: relative;
  flex: 1 1;
}

.label {
  display: inline-block;
  border-radius: 0.25rem;
  background: Blackberry;
  color: White;
  margin-bottom: 1rem;
  padding: 0.25rem 0.5rem;
  composes: fontStyleSubHead;
}

.title {
  display: block;
  margin-bottom: 0.5rem;
  composes: fontStyleSubHead;
  transition: color 300ms ease-in-out;
}

.releaseDate {
  composes: fontStyleXS;
}

.imageWrapper {
  display: block;
  width: 50%;
  overflow: hidden;
  flex: 1 1;
}

.image {
  display: block;
  width: 100%;
  transform-origin: center;
  transition: transform 300ms ease-in-out;
}

.playIcon {
  font-size: 32px;
  line-height: 32px;
  position: absolute;
  bottom: contentPadding;
  right: contentPadding;
  color: Bluecheese;
}

@media BreakpointToMedium {
  .imageWrapper {
    width: 100%;
    height: 0;
    padding-top: 56.25%; /* 16/9 ratio for the image */
    position: relative;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media BreakpointFromMedium {
  .wrapper {
    display: flex;
    justify-content: stretch;
  }
}

.wrapper:hover .image,
.wrapper:focus .image,
.wrapper:active .image {
  transform: scale(1.05);
}

.wrapper:hover .title,
.wrapper:focus .title,
.wrapper:active .title {
  color: Bluecheese;
}
