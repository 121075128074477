@value Coconut from '~goustouicomponents/dist/styles/colors.module.css';
@value BreakpointFromMedium from '~goustouicomponents/dist/design-language/breakpoints.module.css';

.sectionWrapper {
  background-color: Coconut;
}

.sectionWrapper:not(:last-child) {
  margin-bottom: 1rem;
}

.hasPadding {
  padding: 1rem;
}

.isCentered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media BreakpointFromMedium {
  .sectionWrapper:not(:last-child) {
    margin-bottom: 2rem;
  }
}

@media BreakpointFromMedium {
  .hasPadding {
    padding: 2rem;
  }
}
