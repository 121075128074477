@value BreakpointFromMedium, BreakpointFromLarge from '~goustouicomponents/dist/design-language/breakpoints.module.css';
@value BackgroundColor from '../../utils/colors.module.css';

.wrapper {
  margin-bottom: 2rem;
  background: BackgroundColor;
}

.wrapper::before {
  content: '';
  display: block;
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url('../../assets/components/promo/small.jpg');
  background-size: cover;
  padding-top: 65%;
}

.content {
  padding: 1rem;
}

.icon {
  margin-right: 0.5rem;
}

@media BreakpointFromMedium {
  .wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 3.25rem;
  }

  .content {
    padding: 2rem;
  }

  .wrapper::before {
    clip-path: ellipse(100% 120% at 100% 70%);
    align-self: stretch;
    padding-top: 35%;
  }

  .wrapper::before,
  .content {
    flex-basis: 50%;
  }
}

@media BreakpointFromLarge {
  .wrapper {
    margin-bottom: 3.5rem;
  }

  .content {
    padding: 4.5rem;
  }
}
