@value Pomegranate, White from '~goustouicomponents/dist/styles/colors.module.css';

.wrapper {
  background: White;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 10;
}

.logo {
  composes: fontStyleL from '~goustouicomponents/dist/design-language/typography.module.css';
  color: Pomegranate;
  text-decoration: none;
}
