@value BreakpointFromMedium, BreakpointFromLarge from '~goustouicomponents/dist/design-language/breakpoints.module.css';

.episodeContainer {
  background: rgb(97, 92, 255, 0.4);
  border: solid 8px white;
  border-bottom: none;
  position: relative;
  margin-bottom: 1rem;
}

.episodeInfoContainer {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.episodeImage {
  position: absolute;
  top: 0;
  filter: grayscale(1);
  transition: opacity 200ms ease;
  display: block;
  mix-blend-mode: multiply;
}

.episodeInfo {
  z-index: 1;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
  color: white;
  padding-left: 2rem;
  padding-right: 2rem;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.6)) drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4));
}

.episodeHeading {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  margin: 0;
  padding-bottom: 0.5rem;
}

.dateAndDuration {
  margin-top: 0;
  font-size: 0.75rem;
  line-height: 0.75rem;
}

button {
  border-radius: 0 !important;
  border: none !important;
}

.playIcon {
  height: 50%;
  margin-right: 0.5rem;
}

.episodeContainer:hover {
  transition: border 200ms ease;
  border: solid #3e3ac2 8px;
  border-bottom: none;
  overflow: hidden;
}

.episodeContainer:hover > div > img {
  opacity: 1;
  transition: opacity 200ms ease, filter 200ms ease, transform 700ms ease;
  mix-blend-mode: normal;
  transform: scale(1.02);
}

.episodeContainer:hover > .episodeInfoContainer > .episodeInfo {
  transition: display 200ms ease;
  display: none;
}

.episodeContainer:hover > button {
  background: #3e3ac2;
}

@media BreakpointFromMedium {
  .episodeInfo {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .episodeHeading {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
}

@media BreakpointFromLarge {
  .episodeHeading {
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
  }
}
