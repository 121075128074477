@value Blackberry, White from '~goustouicomponents/dist/styles/colors.module.css';
@value BreakpointFromMedium from '~goustouicomponents/dist/design-language/breakpoints.module.css';

.wrapper {
  background: white;
}

.headerContainer {
  color: White;
  background: Blackberry;
  background-image: url(../../assets/backgrounds/heroMessage.svg);
  background-position: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.header {
  text-align: center;
}

.mainSection {
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow-wrap: break-word;
}

.paddingElement {
  padding-bottom: 0.5rem;
}

.orderedList {
  counter-reset: a;
  margin-bottom: 0;
  padding-inline-start: 1rem;
}

.itemList {
  display: block;
  position: relative;
  margin-bottom: 0.5rem;
}

.itemList::before {
  content: counters(a, ".") ".";
  counter-increment: a;
  position: absolute;
  margin-right: 100%;
  right: 0.5rem;
}

@media BreakpointFromMedium {
  .orderedList {
    padding-inline-start: 2rem;
  }
}
